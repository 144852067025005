// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Details_container__W8tgr {
  padding: 20px 40px;
  background-color: #FAFAFA;
}

@media only screen and (max-width: 1560px) {
  .Details_container__W8tgr {
    padding: 10px 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Details/Details.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,yBAAA;AACJ;;AACA;EACI;IACI,kBAAA;EAEN;AACF","sourcesContent":[".container{\n    padding: 20px 40px;\n    background-color: #FAFAFA;\n}\n@media only screen and (max-width: 1560px){\n    .container{\n        padding: 10px 24px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Details_container__W8tgr`
};
export default ___CSS_LOADER_EXPORT___;
