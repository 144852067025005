// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_container__YmQNy {
  margin: 20px 30px;
}

.Table_containerFilters__CZpoE {
  display: flex;
  align-items: center;
}

.Table_containerSearch__b5YvX {
  display: flex;
  align-items: center;
  gap: 22px;
}
.Table_containerSearch__b5YvX .Table_containerBtnFilter__5d8Cv {
  display: flex;
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 {
  color: #06132F;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: start;
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 .Table_input__1DhcE {
  width: 275px;
  border: none;
  font-size: 18px;
  border-bottom: 1px solid #E5E9F0;
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 .Table_input__1DhcE:focus-visible {
  outline: none;
}
.Table_containerSearch__b5YvX .Table_containerBtn__7Y8Bd {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  background-color: #ff6d00;
  color: #fff;
  border-radius: 154px;
  cursor: pointer;
  font-size: 18px;
}

@media only screen and (max-width: 1560px) {
  .Table_container__YmQNy {
    margin: 10px 24px;
  }
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 {
  font-size: 16px;
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 .Table_input__1DhcE {
  font-size: 16px;
}
.Table_containerSearch__b5YvX .Table_containerBtn__7Y8Bd {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAGJ;AAFI;EACI,aAAA;AAIR;AAFI;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAIR;AAHQ;EACI,YAAA;EACA,YAAA;EACA,eAAA;EACA,gCAAA;AAKZ;AAJY;EACI,aAAA;AAMhB;AAFI;EACI,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,yBAAA;EACA,WAAA;EACA,oBAAA;EACA,eAAA;EACA,eAAA;AAIR;;AADA;EACI;IACI,iBAAA;EAIN;AACF;AADI;EACI,eAAA;AAGR;AAFQ;EACI,eAAA;AAIZ;AADI;EACI,eAAA;AAGR","sourcesContent":[".container{\n    margin: 20px 30px\n}\n.containerFilters{\n    display: flex;\n    align-items: center;\n}\n.containerSearch{\n    display: flex;\n    align-items: center;\n    gap: 22px;\n    .containerBtnFilter{\n        display: flex;\n    }\n    .containerInput{\n        color: #06132F;\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 18px;\n        text-align: start;\n        .input{\n            width: 275px;\n            border: none;\n            font-size: 18px;\n            border-bottom: 1px solid #E5E9F0;\n            &:focus-visible{\n                outline: none;\n            }\n        }\n    }\n    .containerBtn{\n        display: inline-flex;\n        padding: 8px 16px;\n        justify-content: center;\n        align-items: center;\n        gap: 4px;\n        flex-shrink: 0;\n        background-color: #ff6d00;\n        color: #fff;\n        border-radius: 154px;\n        cursor: pointer;\n        font-size: 18px;\n    }\n}\n@media only screen and (max-width: 1560px){\n    .container{\n        margin: 10px 24px\n    }\n}\n.containerSearch{\n    .containerInput{\n        font-size: 16px;\n        .input{\n            font-size: 16px;\n        }\n    }\n    .containerBtn{\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Table_container__YmQNy`,
	"containerFilters": `Table_containerFilters__CZpoE`,
	"containerSearch": `Table_containerSearch__b5YvX`,
	"containerBtnFilter": `Table_containerBtnFilter__5d8Cv`,
	"containerInput": `Table_containerInput__ro+I6`,
	"input": `Table_input__1DhcE`,
	"containerBtn": `Table_containerBtn__7Y8Bd`
};
export default ___CSS_LOADER_EXPORT___;
