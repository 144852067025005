// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_container__EfGkk {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

.Input_label__xTHps {
  color: #F5F5F5;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
}

.Input_input__xC1Gr {
  height: 40px;
  border-top: 0;
  border-inline: 0;
  width: 100%;
  background-color: transparent;
  color: #F5F5F5;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.Input_input__xC1Gr:focus-visible {
  outline: none;
}

.Input_containerImg__M4EML {
  position: absolute;
  bottom: 8px;
  right: 0;
  color: #CECCCC;
  cursor: pointer;
}
.Input_containerImg__M4EML svg {
  width: 35px;
  height: 30px;
}

@media only screen and (max-width: 1560px) {
  .Input_label__xTHps {
    font-size: 14px;
    font-weight: 700;
  }
  .Input_input__xC1Gr {
    height: 30px;
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Input/Input.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AACA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AAEJ;;AAAA;EACI,YAAA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;EACA,6BAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAGJ;;AADA;EACI,aAAA;AAIJ;;AAFA;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,cAAA;EACA,eAAA;AAKJ;AAJI;EACI,WAAA;EACA,YAAA;AAMR;;AAHA;EACI;IACI,eAAA;IACA,gBAAA;EAMN;EAJE;IACI,YAAA;IACA,eAAA;EAMN;AACF","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    position: relative;\n}\n.label{\n    color: #F5F5F5;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    width: fit-content;\n}\n.input{\n    height: 40px;\n    border-top: 0;\n    border-inline: 0;\n    width: 100%;\n    background-color: transparent;\n    color: #F5F5F5;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 500;\n}\n.input:focus-visible{\n    outline: none;\n}\n.containerImg{\n    position: absolute;\n    bottom: 8px;\n    right: 0;\n    color: #CECCCC;\n    cursor: pointer;\n    svg{\n        width: 35px;\n        height: 30px;\n    }\n}\n@media only screen and (max-width: 1560px){\n    .label{\n        font-size: 14px;\n        font-weight: 700;\n    }\n    .input{\n        height: 30px;\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Input_container__EfGkk`,
	"label": `Input_label__xTHps`,
	"input": `Input_input__xC1Gr`,
	"containerImg": `Input_containerImg__M4EML`
};
export default ___CSS_LOADER_EXPORT___;
