import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';

export const MenuData = [
    {
        id: 1,
        icon: <DashboardOutlinedIcon />,
        title: "Home",
        route: "/dashboard",
        info: "Inicio",
        breadcrumb: "",
        visible: true
    },
    {
        id: 2,
        icon: <PersonOutlineRoundedIcon />,
        title: "Usuarios",
        route: "/usuarios",
        info: "Usuarios",
        breadcrumb: "Inicio/Usuarios",
        visible: true
    },
    {
        id: 3,
        icon: <AppRegistrationRoundedIcon />,
        title: "Autenticaciones",
        route: "/usuarios/autenticaciones",
        info: "Autenticaciones",
        breadcrumb: "Inicio/Usuarios/Autenticaciones",
        visible: true
    },
    {
        id: 4,
        icon: <HowToRegRoundedIcon />,
        title: "Registraciones",
        route: "/usuarios/registraciones",
        info: "Registraciones",
        breadcrumb: "Inicio/Usuarios/Registraciones",
        visible: true
    },
    {
        id: 3,
        icon: <AppRegistrationRoundedIcon />,
        title: "Autenticaciones",
        route: "/usuarios/autenticaciones/detalles",
        info: "Autenticaciones",
        breadcrumb: "Inicio/Usuarios/Autenticaciones/Detalles autenticación",
        visible: false
    },
    {
        id: 4,
        icon: <HowToRegRoundedIcon />,
        title: "Registraciones",
        route: "/usuarios/registraciones/detalles",
        info: "Registraciones",
        breadcrumb: "Inicio/Usuarios/Registraciones/Detalles registración",
        visible: false
    },
    {
        id: 5,
        icon: <SettingsRoundedIcon />,
        title: "Configuraciones",
        route: "/configuraciones",
        info: "Configuraciones",
        breadcrumb: "Volver",
        visible: true
    }
]