import React from 'react'
import styles from "./Banner.module.scss"

import logoHorizontal from "../../../assets/EVANLOGOHORIZONTAL.svg"
import banner from "../../../assets/BannerBackground.webp"
import { Link } from 'react-router-dom'

export const Banner = () => {
  return (
    <div className={styles.containerBanner}>
    <img src={banner} alt="background" className={styles.backgroundImg} />
    <div className={styles.container}>
        <div className={styles.containerLogo}>
            <img src={logoHorizontal} alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.containerText}>
            <p className={styles.text}>Conoce nuestro motor de Identidad único basado en nuestra IA</p>
            <a href="https://www.miaid.me/tecnologia" target="_blank" rel="noopener noreferrer"><div className={styles.btn}>Ver más</div></a>
        </div>
    </div>
    </div>
  )
}
