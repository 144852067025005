// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_container__AJMcC {
  padding: 20px 30px;
}
.Dashboard_container__AJMcC .Dashboard_title__RUbrd {
  font-size: 30px;
  font-weight: 600;
  color: #202532;
  text-align: start;
  margin: 0;
  margin-bottom: 16px;
}
.Dashboard_container__AJMcC .Dashboard_containerGrid__k6F\\+C {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}
.Dashboard_container__AJMcC .Dashboard_containerGrid__k6F\\+C .Dashboard_containerDiv__rhfve {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media only screen and (max-width: 1560px) {
  .Dashboard_container__AJMcC {
    padding: 10px 24px;
  }
  .Dashboard_container__AJMcC .Dashboard_title__RUbrd {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,SAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,8BAAA;EACA,wBAAA;EACA,qBAAA;EACA,kBAAA;AAER;AADQ;EACI,aAAA;EACA,8BAAA;EACA,wBAAA;EACA,qBAAA;EACA,kBAAA;AAGZ;;AACA;EACI;IACI,kBAAA;EAEN;EADM;IACI,eAAA;IACA,gBAAA;IACA,mBAAA;EAGV;AACF","sourcesContent":[".container {\n    padding: 20px 30px;\n    .title{\n        font-size: 30px;\n        font-weight: 600;\n        color: #202532;\n        text-align: start;\n        margin: 0;\n        margin-bottom: 16px;\n    }\n    .containerGrid {\n        display: grid;\n        grid-template-columns: 2fr 1fr;\n        grid-template-rows: auto;\n        grid-column-gap: 16px;\n        grid-row-gap: 16px;\n        .containerDiv {\n            display: grid;\n            grid-template-columns: 1fr 1fr;\n            grid-template-rows: auto;\n            grid-column-gap: 16px;\n            grid-row-gap: 16px;\n        }\n    }\n}\n@media only screen and (max-width: 1560px){\n    .container {\n        padding: 10px 24px;\n        .title{\n            font-size: 26px;\n            font-weight: 600;\n            margin-bottom: 12px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Dashboard_container__AJMcC`,
	"title": `Dashboard_title__RUbrd`,
	"containerGrid": `Dashboard_containerGrid__k6F+C`,
	"containerDiv": `Dashboard_containerDiv__rhfve`
};
export default ___CSS_LOADER_EXPORT___;
