import { React, useState, useEffect } from "react";

import styles from "./ListDrawer.module.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { MenuData } from "../../const/MenuData";

export default function ListDrawer({ setOpen }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState({});
    const location = useLocation();
    const navigate = useNavigate()

    const handleClick = (route) => {
        setOpen(false);
        navigate(route)
    };

    return (
        <div className={styles.container}>
            {MenuData.map((item, index) => (
                item.visible && <div key={item.id} className={`${styles.containerItem} ${location.pathname === item.route && styles.select}`} onClick={() => handleClick(item.route)}>
                        <div className={styles.itemListIcon}>
                            {item.icon}
                        </div>
                        <div className={styles.itemListText}>
                            {item.title}
                        </div>
                    </div>
            ))}
        </div>
    );
}
