import { useEffect, useState } from 'react';
import './App.css';
import Table from './components/Table/Table';
import { Context } from './context/Context';
import SignIn from './components/SignIn/SignIn';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Autenticaciones from './components/Autenticaciones/Autenticaciones';
import Registraciones from './components/Registraciones/Registraciones';
import Details from './components/Details/Details';
import { ChangePass } from './components/ChangePass/ChangePass';
import { Dashboard } from './components/Dashboard/Dashboard';
import { Config } from './components/Config/Config';

function App() {
  const [modal, setModal] = useState("")
  const [seeStorage, setSeeStorage] = useState(false)
  const [selectRow, setSelectRow] = useState({ status: false, row: {} })
  const [detailRow, setDetailRow] = useState({ status: false, row: {} })
  const [snackbarInfo, setSnackbarInfo] = useState({ open: false, message: "", severity: "" })
  const [log, setLog] = useState(false)

  useEffect(() => {
    let data = sessionStorage.getItem("user");
    if(data){
      setLog(true)
    } else{
      setLog(false)
    }
  }, [seeStorage])
  const router = createBrowserRouter([
    {
      path: "/",
      element: <SignIn storage={seeStorage} setStorage={setSeeStorage}/>,
    },
    {
      path: "/dashboard",
      element: <Dashboard/>,
    },
    {
      path: "/configuraciones",
      element: <Config />
    },
    {
      path: "/cambio-de-password",
      element: <ChangePass/>,
    },
    {
      path: "/usuarios",
      element: <Table />
    },
    {
      path: "/usuarios/autenticaciones",
      element: <Autenticaciones />
    },
    {
      path: "/usuarios/registraciones",
      element: <Registraciones />
    },
    {
      path: "/usuarios/autenticaciones/detalles",
      element: <Details />
    },
    {
      path: "/usuarios/registraciones/detalles",
      element: <Details />
    },
  ]);
  return (
    <div className="App">
      <Context.Provider value={{modal, setModal, selectRow, setSelectRow, detailRow, setDetailRow, snackbarInfo, setSnackbarInfo}}>
        <RouterProvider router={router} />
      </Context.Provider>
    </div>
  );
}

export default App;
