import React from 'react'
import styles from "./BackBtn.module.scss"
import { useNavigate } from 'react-router-dom';

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export const BackBtn = ({text, route}) => {
    const navigate = useNavigate()
  return (
    <div className={styles.containerBack} onClick={() => navigate(route)}>
        <ArrowBackIosNewRoundedIcon/>
        <p>{text}</p>
    </div>
  )
}
