import React, { useRef, useState } from 'react'
import styles from "./Config.module.scss"
import Header from '../Header/Header'
import { Form, Formik } from 'formik'
import { FormikTextInput } from '../Inputs/FormikInputs'
import * as yup from "yup";
import { Button } from '../UI/Button/Button'
import { useNavigate } from 'react-router-dom'

export const Config = () => {
  const [step, setStep] = useState(0)
  const tenantRef = useRef()
  const umbralRef = useRef()
  const servidorRef = useRef()
  const navigate = useNavigate()

  const handleStep = (step) => {
    setStep(step)
  }
  const handleBack = () => {
    navigate(-1)
}
  return (
    <>
        <Header/>
        <div className={styles.container}>
            <div className={styles.containerModal}>
                <div className={styles.containerTitle}>
                    <h2>Configuraciones</h2>
                </div>
                <div className={styles.containerSelect}>
                  <div onClick={() => handleStep(0)} className={step === 0 ? styles.select : ""}>Tenant</div>
                  <div onClick={() => handleStep(1)} className={step === 1 ? styles.select : ""}>Umbrales</div>
                  <div onClick={() => handleStep(2)} className={step === 2 ? styles.select : ""}>Servidor</div>
                </div>
                <div className={styles.containerStep}>
                  {step === 0 && 
                    <div>
                      <h2 className={styles.titleConfig}>Tenant</h2>
                      <Formik
                        innerRef={tenantRef}
                        initialValues={{
                          name: "",
                          host: "",
                          description: ""
                      }}
                      validationSchema={
                          yup.object({
                            name: yup.string().required("El nombre es requerido"),
                            host: yup.string().required("El host es requerido"),
                          })
                      }
                      onSubmit={(values) => {
                          console.log(values);
                      }}>
                        <Form className={styles.containerForm}>
                          <div>
                        <div className={styles.containerInputs}>
                          <FormikTextInput
                              fullWidth={true}
                              name="name"
                              labelText="Nombre"
                              placeholder="Nombre del servidor"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="host"
                              labelText="Host"
                              placeholder="123456789000"
                          />
                        </div>
                        <FormikTextInput
                              fullWidth={true}
                              name="description"
                              labelText="Descripción (opcional)"
                              placeholder="Descripción"
                          />
                          </div>
                          <div className={styles.btnContainer}>
                            <Button text="Cancelar" size="md" type='transparent' click={() => handleBack()} />
                            <Button text="Guardar" size="md" />
                          </div>
                          </Form>
                      </Formik>
                    </div>}
                    {step === 1 && 
                    <div>
                      <h2 className={styles.titleConfig}>Umbrales</h2>
                      <Formik
                        innerRef={umbralRef}
                        initialValues={{
                          score: "",
                          dist: "",
                          far: "",
                          frr: "",
                          Calidad: "",
                          liveness: "",
                          verifLive: "",
                      }}
                      validationSchema={
                          yup.object({
                            score: yup.string().required("El score es requerido"),
                            dist: yup.string().required("La distancia es requerida"),
                            far: yup.string().required("El FAR es requerido"),
                            frr: yup.string().required("El FRR es requerido"),
                            Calidad: yup.string().required("La calidad es requerida"),
                            liveness: yup.string().required("El liveness es requerido"),
                            verifLive: yup.string().required("El liveness es requerido"),
                          })
                      }
                      onSubmit={(values) => {
                          console.log(values);
                      }}>
                        <Form className={styles.containerForm}>
                          <div>
                        <div className={styles.containerInputs}>
                          <FormikTextInput
                              fullWidth={true}
                              name="score"
                              labelText="Score"
                              placeholder="0,782"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="dist"
                              labelText="Distancia"
                              placeholder="0,782"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="far"
                              labelText="FAR"
                              placeholder="Tenant"
                          />
                        </div>
                        <div className={styles.containerInputs}>
                          <FormikTextInput
                              fullWidth={true}
                              name="frr"
                              labelText="FRR"
                              placeholder="123456789"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="calidad"
                              labelText="Calidad"
                              placeholder="High"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="Liveness"
                              labelText="liveness"
                              placeholder="010101010101"
                          />
                        </div>
                          </div>
                          <div className={styles.btnContainer}>
                            <Button text="Cancelar" size="md" type='transparent' click={() => handleBack()} />
                            <Button text="Guardar" size="md" />
                          </div>
                          </Form>
                      </Formik>
                    </div>}
                    {step === 2 && 
                    <div>
                      <h2 className={styles.titleConfig}>Servidor</h2>
                      <Formik
                        innerRef={servidorRef}
                        initialValues={{
                          name: "",
                          host: "",
                          user: "",
                          pass: "",
                          hql: "",
                          lql: "",
                          ovni: "",
                          ovniKey: "",
                          whiteList: "",
                          blackList: "",
                      }}
                      validationSchema={
                          yup.object({
                            name: yup.string().required("El nombre es requerido"),
                            host: yup.string().required("El host es requerido"),
                          })
                      }
                      onSubmit={(values) => {
                          console.log(values);
                      }}>
                        <Form className={styles.containerForm}>
                          <div>
                          <div className={styles.containerInputs}>
                          <FormikTextInput
                              fullWidth={true}
                              name="name"
                              labelText="Nombre"
                              placeholder="Nombre del servidor"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="host"
                              labelText="Host"
                              placeholder="1234568789000"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="user"
                              labelText="Usuario"
                              placeholder="Tenant"
                          />
                        </div>
                        <div className={styles.containerInputs}>
                          <FormikTextInput
                              fullWidth={true}
                              name="pass"
                              labelText="Contraseña"
                              placeholder="1234567809jf"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="hql"
                              labelText="High quality liveness URL"
                              placeholder="High quality liveness URL"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="lql"
                              labelText="Low quality liveness URL"
                              placeholder="Low quality liveness URL"
                          />
                        </div>
                        <div className={styles.containerInputs}>
                          <FormikTextInput
                              fullWidth={true}
                              name="ovni"
                              labelText="OVNI URL"
                              placeholder="OVNI URL"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="ovniKey"
                              labelText="OVNI Key"
                              placeholder="OVNI Key"
                          />
                          <FormikTextInput
                              fullWidth={true}
                              name="whiteList"
                              labelText="ID lista blanca"
                              placeholder="rest/V1/authencation/36498489am..."
                          />
                        </div>
                        <FormikTextInput
                              fullWidth={true}
                              name="blackList"
                              labelText="ID lista negra"
                              placeholder="rest/V1/authencation/36498489am..."
                          />
                          </div>
                          <div className={styles.btnContainer}>
                            <Button text="Cancelar" size="md" type='transparent' click={() => handleBack()} />
                            <Button text="Guardar" size="md" />
                          </div>
                          </Form>
                      </Formik>
                    </div>}
                </div>
            </div>
        </div>
    </>
  )
}
