export const columns = [
    { field: 'usuarioEVANId', headerName: 'EVAN ID', flex: 1, minWidth: 100 },
    { field: 'registracionPrimera', headerName: 'Primera registración', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'registracionUltima', headerName: 'Ultima registración', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'registracionCantidad', headerName: 'Registraciones', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    { field: 'autenticacionCantidad', headerName: 'Autenticaciones', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    { field: 'autenticacionUltima', headerName: 'Última autenticación', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    // { field: 'autenticacionCantidad', headerName: 'Cant. autenticaciones', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
  ];

  export const colsAuth = [
    { field: 'autenticacionFecha', headerName: 'Fecha', flex: 1, minWidth: 100, align: "left", headerAlign: 'left' },
    { field: 'autenticacionResultadoOK', headerName: 'Resultado', flex: 1, minWidth: 100, renderCell: (params) => (
      <div style={{backgroundColor: `${params.value === "Match" ? "rgba(2, 137, 59, 0.10)" : params.value === "Error" ? "rgba(216, 17, 17, 0.10)" : "rgba(2, 80, 137, 0.10)"}`, minWidth: "60px",width: "auto", padding: "8px", borderRadius:"60px", height:"24px", display: "flex", alignItems: "center", gap: "4px"}}>
        <div style={{backgroundColor: `${params.value === "Match" ? "#02893B" : params.value === "Error" ? "#D81111" : "#025089"}`, width:"8px", height:"8px", borderRadius: "50%"}}></div>
        <p
          style={{color: `${params.value === "Match" ? "#02893B" : params.value === "Error" ? "#D81111" : "#025089"}`, margin:0, fontSize: "14px"}}
        >
          {params.value}
        </p>
      </div>
    ), align: "center", headerAlign: 'center'},
    { field: 'autenticacionScoreCalculado', headerName: 'Score', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    { field: 'factorNombre', headerName: 'Factor', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'autenticacionFaR', headerName: 'FMR', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    { field: 'autenticacionFrR', headerName: 'FNMR', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    { field: 'autenticacionLiveness', headerName: 'Liveness', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'autenticacionLivenessConfidence', headerName: 'Confidence', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    { field: 'autenticacionModo', headerName: 'Variante', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'autenticacionId', headerName: 'Autenticación ID', flex: 1, minWidth: 100 },
  ];

  export const colsReg = [
    { field: 'registracionFecha', headerName: 'Fecha', flex: 1, minWidth: 100, align: "left", headerAlign: 'left' },
    { field: 'registracionResultadoOK', headerName: 'Resultado', flex: 1, minWidth: 100, renderCell: (params) => (
      <div style={{backgroundColor: `${params.value === "Match" ? "rgba(2, 137, 59, 0.10)" : "rgba(216, 17, 17, 0.10)"}`, minWidth: "60px", width: "auto", padding: "8px", borderRadius:"60px", height:"24px", display: "flex", alignItems: "center", gap: "4px"}}>
        <div style={{backgroundColor: `${params.value === "Match" ? "#02893B" : "#D81111"}`, width:"8px", height:"8px", borderRadius: "50%"}}></div>
        <p
          style={{color: `${params.value === "Match" ? "#02893B" : "#D81111"}`, margin:0, fontSize: "14px"}}
        >
          {params.value}
        </p>
      </div>
    ), align: "center", headerAlign: 'center'},
    { field: 'factorNombre', headerName: 'Factor', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'factorVarianteNombre', headerName: 'Subfactor', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'registracionResultadoCod', headerName: 'Código', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'registracionResultadoMotivo', headerName: 'Motivo', flex: 1, minWidth: 100 },
    { field: 'registracionLowQuality', headerName: 'Servidor de Liveness', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'registracionLatitud', headerName: 'Latitud', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'registracionLongitud', headerName: 'Longitud', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    { field: 'registracionId', headerName: 'Registración ID', flex: 1, minWidth: 100 },
  ];
