import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const getDashboardData = async(tenantNombre) => {
    let data
    try {
        data = await axios.post(
            serviceURL,
            {
                service: "EVANDashboardDatosGet",
                params: {
                    tenantNombre: tenantNombre,
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return data.data
}