import React from 'react'
import styles from "./IdBlock.module.scss"

export const IdBlock = ({id}) => {
  return (
    <div className={styles.container}>
        <h2>EVAN ID: </h2>
        <h3>{id}</h3>
    </div>
  )
}
