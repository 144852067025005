import React from 'react'
import styles from "./Button.module.scss"

export const Button = ({text, size, click, disabled, type ="", children}) => {
  return (
        <button className={`${styles.btn} ${styles[size]} ${styles[type]}`} onClick={click} disabled={disabled}>
            {text || children}
        </button>
  )
}
