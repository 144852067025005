// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Registraciones_container__PmYnu {
  padding: 20px 30px;
}

.Registraciones_containerTop__QPfK3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.Registraciones_containerTop__QPfK3 h2 {
  color: #06132F;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  text-align: start;
}

.Registraciones_containerTop__QPfK3 h3 {
  color: #FF6909;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  margin: 0;
  text-align: start;
}

@media only screen and (max-width: 1560px) {
  .Registraciones_container__PmYnu {
    padding: 10px 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Registraciones/Registraciones.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,aAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,iBAAA;AAGJ;;AADA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,iBAAA;AAIJ;;AAFA;EACI;IACI,kBAAA;EAKN;AACF","sourcesContent":[".container{\n    padding: 20px 30px;\n}\n.containerTop{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n}\n.containerTop h2 {\n    color: #06132F;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 24px;\n    margin: 0;\n    text-align: start;\n}\n.containerTop h3 {\n    color: #FF6909;\n    font-size: 32px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 38px;\n    margin: 0;\n    text-align: start;\n} \n@media only screen and (max-width: 1560px){\n    .container{\n        padding: 10px 24px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Registraciones_container__PmYnu`,
	"containerTop": `Registraciones_containerTop__QPfK3`
};
export default ___CSS_LOADER_EXPORT___;
