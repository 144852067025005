// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Snackbar_root__8o0Gb {
  color: white;
  padding: 1.2em 0.8em !important;
  position: absolute;
  top: 0px !important;
  transition: all 1s ease-in-out !important;
  width: 100%;
  z-index: 99999999 !important;
}
.Snackbar_root__8o0Gb .MuiSnackbarContent-root {
  background-color: transparent;
  box-shadow: none;
  font-size: 1rem;
  min-width: unset;
  padding: 0;
}

.Snackbar_success__6L\\+iG {
  background-color: #30C952;
}

.Snackbar_error__lj9UL {
  background-color: #FF3C34;
}

.Snackbar_warning__ICD4A {
  background-color: #ffa91c;
}

.Snackbar_alert__JTN-w {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Snackbar/Snackbar.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,+BAAA;EACA,kBAAA;EACA,mBAAA;EACA,yCAAA;EACA,WAAA;EACA,4BAAA;AACJ;AACI;EACI,6BAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;AACR;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;AAAJ","sourcesContent":[".root {\n    color: white;\n    padding: 1.2em 0.8em !important;\n    position: absolute;\n    top: 0px !important;\n    transition: all 1s ease-in-out !important;\n    width: 100%;\n    z-index: 99999999 !important;\n\n    :global .MuiSnackbarContent-root {\n        background-color: transparent;\n        box-shadow: none;\n        font-size: 1rem;\n        min-width: unset;\n        padding: 0;\n    }\n}\n\n.success {\n    background-color: #30C952;\n}\n\n.error {\n    background-color: #FF3C34;\n}\n\n.warning {\n    background-color: #ffa91c;\n}\n\n.alert {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #ffffff;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Snackbar_root__8o0Gb`,
	"success": `Snackbar_success__6L+iG`,
	"error": `Snackbar_error__lj9UL`,
	"warning": `Snackbar_warning__ICD4A`,
	"alert": `Snackbar_alert__JTN-w`
};
export default ___CSS_LOADER_EXPORT___;
