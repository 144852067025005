// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IdBlock_container__H0BXU {
  display: flex;
  align-items: center;
  gap: 8px;
}

.IdBlock_container__H0BXU h2 {
  color: #06132F;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  text-align: start;
  width: max-content;
}

.IdBlock_container__H0BXU h3 {
  color: #FF6909;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  margin: 0;
  text-align: start;
}

@media only screen and (max-width: 1560px) {
  .IdBlock_container__H0BXU h2 {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .IdBlock_container__H0BXU h3 {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/IdBlock/IdBlock.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;;AACA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,iBAAA;AAGJ;;AADA;EACI;IACI,0BAAA;IACA,4BAAA;EAIN;EAFE;IACI,0BAAA;IACA,4BAAA;EAIN;AACF","sourcesContent":[".container{\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n.container h2 {\n    color: #06132F;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 24px;\n    margin: 0;\n    text-align: start;\n    width: max-content;\n}\n.container h3 {\n    color: #FF6909;\n    font-size: 30px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 38px;\n    margin: 0;\n    text-align: start;\n} \n@media only screen and (max-width: 1560px){\n    .container h2 {\n        font-size: 16px !important;\n        line-height: 20px !important;\n    }\n    .container h3 {\n        font-size: 20px !important;\n        line-height: 28px !important;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IdBlock_container__H0BXU`
};
export default ___CSS_LOADER_EXPORT___;
