import React from 'react'
import styles from "./CardStats.module.scss"

import userImg from "../../../assets/IconUsers.svg"
import MovingIcon from '@mui/icons-material/Moving';

export const CardStats = ({title, value, stonk = true}) => {
  return (
    <div className={styles.containerCard}>
        <div className={styles.containerTop}>
            <h2 className={styles.title}>{title || "Total"}</h2>
        </div>
        <div className={styles.container}>
            <p className={styles.text}>{value || 0}</p>
            <img src={userImg} alt="user" className={styles.img} />
        </div>
        <div className={styles.containerBottom}>
            {stonk ? <><MovingIcon/>
            <p className={styles.bottomText}><span>8.5%</span> más que el mes pasado</p></> : <></>}
        </div>
    </div>
  )
}
