import React, { useEffect, useState } from 'react'
import styles from "./CardGraph.module.scss"
import { LineChart, lineElementClasses, PieChart } from '@mui/x-charts'

import userImg from "../../../assets/IconUsers.svg"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const CardGraph = ({title, type = "line", data}) => {
  const [btnSelected, setBtnSelected] = useState("Auth")
  const [percentageOk, setPercentageOk] = useState(0)
  const [percentageFail, setPercentageFail] = useState(0)
  const [mesIndex, setMesIndex] = useState(11)
  const meses = data.map(data => data.mes)

  const mesModified = (dir) => {
    if(dir === "ant"){
      if(mesIndex === 0){
        setMesIndex(meses.length - 1)
      }else{
        setMesIndex(mesIndex - 1)
      }
    }else{
      if(mesIndex === meses.length - 1){
        setMesIndex(0)
      }else{
        setMesIndex(mesIndex + 1)
      }
    }
  }
  useEffect(() => {
    if(type === "pie"){
      let ok =Math.round(data[mesIndex]?.totalAutenticacionesExitosas/data[mesIndex]?.totalAutenticaciones * 100)
      let fail =Math.round(data[mesIndex]?.totalAutenticacionesFallidas/data[mesIndex]?.totalAutenticaciones * 100)
      setPercentageOk(ok)
      setPercentageFail(fail)
      }}, [data, mesIndex, meses])
  
  return (
    <div className={styles.containerCard}>
        <div className={styles.containerTop}>
            <h2 className={styles.title}>{title || "Total"}</h2>
            {type === "line" ? <div className={styles.containerBtns}>
              <div className={`${styles.btn} ${btnSelected === "Auth" && styles.select}`} onClick={() => setBtnSelected("Auth")}>
                Autenticaciones
              </div>
              <div className={`${styles.btn} ${btnSelected === "Reg" && styles.select}`} onClick={() => setBtnSelected("Reg")}>
                Registraciones
              </div>
            </div> : 
            <div className={styles.containerBtns}>
              <div className={styles.containerInput}>
                <label className={styles.label}>Gestión</label>
                <select className={styles.select}>
                  <option>Todos</option>
                </select>
              </div>
              <div className={styles.containerInput}>
                <label className={styles.label}>Canal</label>
                <select className={styles.select}>
                  <option>Todos</option>
                </select>
              </div>
          </div>}
        </div>
        <div className={styles.container}>
        {type === "line" && 
        <>
          <div className={styles.containerData}>
            <img src={userImg} alt="user" className={styles.img} />
            <div className={styles.containerText}>
              <p>Total Usuarios</p>
              <div className={styles.containerNum}>
                <p>{data?.reduce((acc, obj) => acc + obj.totalUsuarios, 0)} </p>
                <div><ChevronLeftIcon/>43.9%</div>
              </div>
            </div>
          </div>
          <LineChart
              xAxis={[{ scaleType: 'point', data: data.map(e => e.mes) }]}
              series={[
                  {
                  id: "stats",
                  data: btnSelected === "Auth" ? data.map(e => e.totalAutenticaciones) : data.map(e => e.totalRegistros),
                  showMark: false,
                  area: true,
                  color: "#FF6D00",
                  },
              ]}
              // width={"100%"}
              height={250}
              margin={{ top: 20, right: 10, bottom: 20, left: 30 }}
              grid={{ vertical: true, horizontal: true }}
              sx={{
                  [`& .${lineElementClasses.root}`]: {
                      strokeWidth: 3,
                      
                    },
                  '& .MuiAreaElement-series-stats': {
                    fill: "url('#myGradient')",
                  },
                }}
          >
              <defs>
                  <linearGradient id="myGradient" gradientTransform="rotate(90)">
                  <stop offset="10%" stopColor="#FF6D0077" />
                  <stop offset="95%" stopColor="#FF6D0000" />
                  </linearGradient>
              </defs>

          </LineChart>
        </>}
        {type === "pie" && 
        <>
          <div className={styles.containerFilter}>
            <ChevronLeftIcon className={styles.icon} onClick={() => mesModified("ant")}/>
            <p>{meses[mesIndex]}</p>
            <ChevronRightIcon className={styles.icon} onClick={() => mesModified("sig")}/>
          </div>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.value}%`,
                arcLabelMinAngle: 10,
                arcLabelRadius: '75%',
                data: [
                    {
                        label: 'Error',
                        value: percentageFail,
                        color: '#F04E4E',
                    },
                    {
                        label: 'Match',
                        value: percentageOk,
                        color: '#2DCF71',
                    },
                ],
                innerRadius: 60,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: { innerRadius: 50, additionalRadius: -10, color: 'gray' },
                valueFormatter: (item) => `${item.value}%`,
              },
            ]}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            height={250}
            // maxHeight={250}
            slotProps={{ legend: { hidden: true } }}
        />
        <div className={styles.containerNames}><div><div></div>Match</div><div><div></div>Error</div></div>
      </>}
        </div>
    </div>
  )
}
