// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackBtn_containerBack__f21-6 {
  display: flex;
  color: #FF6909;
  cursor: pointer;
  margin-bottom: 8px;
  gap: 4px;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
}
.BackBtn_containerBack__f21-6 p {
  color: #06132F;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
}
.BackBtn_containerBack__f21-6 svg {
  height: 24px;
  width: 24px;
}

@media only screen and (max-width: 1560px) {
  .BackBtn_containerBack__f21-6 {
    margin-bottom: 4px;
  }
  .BackBtn_containerBack__f21-6 p {
    font-size: 13px;
    font-weight: 700;
  }
  .BackBtn_containerBack__f21-6 svg {
    height: 18px;
    width: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/BackBtn/BackBtn.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;EACA,uBAAA;EAAA,kBAAA;EACA,mBAAA;AACJ;AAAI;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,yBAAA;AAER;AAAI;EACI,YAAA;EACA,WAAA;AAER;;AACA;EACI;IACI,kBAAA;EAEN;EADM;IACI,eAAA;IACA,gBAAA;EAGV;EADM;IACI,YAAA;IACA,WAAA;EAGV;AACF","sourcesContent":[".containerBack{\n    display: flex;\n    color: #FF6909;\n    cursor: pointer;\n    margin-bottom: 8px;\n    gap: 4px;\n    width: fit-content;\n    align-items: center;\n    p{\n        color: #06132F;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 24px;\n        margin: 0;\n        text-transform: uppercase;\n    }\n    svg{\n        height: 24px;\n        width: 24px;\n    }\n}\n@media only screen and (max-width: 1560px){\n    .containerBack{\n        margin-bottom: 4px;\n        p{\n            font-size: 13px;\n            font-weight: 700;\n        }\n        svg{\n            height: 18px;\n            width: 18px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBack": `BackBtn_containerBack__f21-6`
};
export default ___CSS_LOADER_EXPORT___;
