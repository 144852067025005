// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Autenticaciones_container__3Fn2u {
  padding: 20px 30px;
}

.Autenticaciones_containerBack__GxVqT {
  display: flex;
  color: #FF6909;
  cursor: pointer;
  margin-bottom: 30px;
  gap: 4px;
  width: -moz-fit-content;
  width: fit-content;
}
.Autenticaciones_containerBack__GxVqT p {
  color: #06132F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.Autenticaciones_containerTop__nVoAe {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 1560px) {
  .Autenticaciones_container__3Fn2u {
    padding: 10px 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Autenticaciones/Autenticaciones.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,aAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,QAAA;EACA,uBAAA;EAAA,kBAAA;AAEJ;AADI;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;AAGR;;AAAA;EACI,aAAA;EACA,WAAA;EACA,8BAAA;AAGJ;;AADA;EACI;IACI,kBAAA;EAIN;AACF","sourcesContent":[".container{\n    padding: 20px 30px;\n}\n.containerBack{\n    display: flex;\n    color: #FF6909;\n    cursor: pointer;\n    margin-bottom: 30px;\n    gap: 4px;\n    width: fit-content;\n    p{\n        color: #06132F;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 24px;\n        margin: 0;\n    }\n}\n.containerTop{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n}\n@media only screen and (max-width: 1560px){\n    .container{\n        padding: 10px 24px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Autenticaciones_container__3Fn2u`,
	"containerBack": `Autenticaciones_containerBack__GxVqT`,
	"containerTop": `Autenticaciones_containerTop__nVoAe`
};
export default ___CSS_LOADER_EXPORT___;
