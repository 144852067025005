import { CircularProgress } from "@mui/material";
import { getImgAutenticacion } from "../../../Services/getImgAutenticacion";
import styles from "./Auth.module.scss"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../UI/Card/Card";
import Modal from "../Modal";
import Map from "../../Map/Map";
import { Button } from "../../UI/Button/Button";
import { IdBlock } from "../../UI/IdBlock/IdBlock";
import { getImg } from "../../../Services/getImg";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

export default function Auth({index, data, tenantId}) {
  const [load, setLoad] = useState(false)
  const [img, setImg] = useState("")
  const [indexSelect, setIndexSelect] = useState(index)
  const [modal, setModal] = useState(false) 
  const navigate = useNavigate()
  const [imgLoad, setImgLoad] = useState(true)
  const urlParam = sessionStorage.getItem("tenantId")
  // console.log(indexSelect, data[indexSelect])

  const handleSumIndex = () => {
    if(indexSelect < data.length-1){
      setIndexSelect(indexSelect+1)

    }
  }

  const handleRestIndex = () => {
    if(indexSelect !== 0){
      setIndexSelect(indexSelect-1)
    }
  }

  const getImages =  async () => {
    if(data) {
      setImgLoad(true)
      let resultUrl = await getImgAutenticacion(Number(urlParam), data[indexSelect]?.autenticacionId, data[indexSelect]?.usuarioId) 
      setImg(resultUrl)
      setImgLoad(false)
      }
  }
  useEffect(() => {
    setIndexSelect(index)
  }, [index])
  useEffect(() => {
    if(data.length > 0){
      getImages()
      setLoad(true)
    }
  }, [data, indexSelect])
  console.log(data)
  return (
    <>
    {/* {modal && <Modal data={data[indexSelect]} close={() => setModal(false)}/>} */}
    <div className={styles.container}>
      {/* <BackBtn text={"Volver a autenticaciones"} route={"/usuarios/autenticaciones"}/> */}
      {load ? 
      <>
      <div className={styles.containerHeader}>
      <IdBlock id={data[indexSelect]?.usuarioEVANId}/>
      <div className={styles.containerBtn}>
          <Button click={handleRestIndex} size={"md"} disabled={indexSelect === 0 ? true : false}>
            <ArrowBackRoundedIcon/>
          </Button>
          <Button click={handleSumIndex} size={"md"} disabled={indexSelect === data.length-1}>
            <ArrowForwardRoundedIcon/>
          </Button>
      </div>
      </div>
      <div className={styles.containerSectionTop}>
        <div className={styles.containerBlock}>
          <div className={styles.containerFirstInfo}>
            {imgLoad ? <div className={styles.avatarImgLoad}><CircularProgress /></div> : <img src={img?.result[0]?.autenticacionImagenURL} className={styles.avatarImg} alt="imagen registracion"/> }
            <div className={styles.containerPrincipalData}>
              <div className={styles.containerData}>
                <p className={`${styles.text} ${styles.bold}`}>Autenticación ID:</p>
                <p className={`${styles.text} ${styles.big}`}>{data[indexSelect]?.autenticacionId}</p>
              </div>
              <div className={styles.containerData}>
                <p className={`${styles.text} ${styles.bold}`}>Fecha:</p>
                <p className={`${styles.text}`}>{data[indexSelect]?.autenticacionFecha.split(" ")[0]}</p>
              </div>
              <div className={styles.containerData}>
                <p className={`${styles.text} ${styles.bold}`}>Hora:</p>
                <p className={`${styles.text}`}>{`${data[indexSelect]?.autenticacionFecha.split(" ")[1]} hs`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerCards}>
            <Card principal={{title: "", result: data[indexSelect]?.autenticacionResultadoOK}} second={{title:"Score", result: data[indexSelect]?.autenticacionScore.toFixed(3)}}/>
            <Card principal={{title: "Liveness", result: data[indexSelect]?.autenticacionLiveness === "Y" ? "Pass" : "Error"}} second={{title:"Score", result: data[indexSelect]?.autenticacionLivenessConfidence.toFixed(3)}} third={{title: "Modelo", result:data[indexSelect]?.autenticacionLowQuality === "Y" ? "Baja" : "Alta" }} livenness openModal={() => setModal(true)} />
            <Card principal={{title: "Autenticador", result: "Activo"}} />
          </div>
          </div>
          <div className={styles.containerSectionBottom}>
          <div className={`${styles.containerBlock} ${styles.heigthCustom}`}>
            <div className={styles.containerGeo}>
              <div className={styles.contianerGeoInfo}>
                <div className={styles.containerTitle}>
                  <p>Geolocalización</p>
                </div>
                <div className={styles.containerLatLng}>
                  <div className={styles.containerHorizontal}>
                    <p className={`${styles.text} ${styles.bold}`}>Latitud:</p>
                    <p className={`${styles.text}`}>{data[indexSelect]?.autenticacionLatitud}</p>
                  </div>
                  <div className={styles.containerHorizontal}>
                    <p className={`${styles.text} ${styles.bold}`}>Longitud:</p>
                    <p className={`${styles.text}`}>{data[indexSelect]?.autenticacionLongitud}</p>
                  </div>
                </div>
              </div>
              <div className={styles.containerMap}>
                <Map lat={data[indexSelect]?.autenticacionLatitud} lng={data[indexSelect]?.autenticacionLongitud}/>
              </div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.rowTop}>
            <div className={`${styles.containerBlock} ${styles.heigthCustom}`}>
              <div className={styles.containerLeft}>
                <div className={styles.leftBlock}>
                  <div className={styles.containerTitleFull}>
                    <p>Factor de registración</p>
                  </div>
                  <div className={styles.containerInfo}>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Nombre</p>
                      <p className={`${styles.text}`}>{data[indexSelect]?.factorNombre}</p>
                    </div>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Variante</p>
                      <p className={`${styles.text}`}>{data[indexSelect]?.factorVarianteNombre}</p>
                    </div>
                  </div>
                  <div className={styles.containerTitleFull}>
                    <p>Data autenticador</p>
                  </div>
                  <div className={styles.containerInfoVertical}>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Internal Sample ID</p>
                      <p className={`${styles.text}`}>N/A</p>
                    </div>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Internal ID</p>
                      <p className={`${styles.text}`}>N/A</p>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
            <div className={styles.containerBlock}>
              <div className={styles.containerLeft}>
                <div className={styles.leftBlock}>
                  <div className={styles.containerTitleFull}>
                    <p>Datos de la captura del rostro</p>
                  </div>
                    <Modal data={data[indexSelect]}/>
                  </div>
              </div>
            </div>
            </div>
            <div className={styles.rowBottom}>
            <div className={styles.containerBlock}>
              <div className={styles.containerLeft}>
                <div className={styles.leftBlock}>
                  <div className={styles.containerTitleFull}>
                    <p>Información del Canal</p>
                  </div>
                  <div className={styles.containerInfo}>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Canal</p>
                      <p className={`${styles.text}`}>Mobile</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.containerBlock}>
              <div className={styles.containerLeft}>
                <div className={styles.leftBlock}>
                  <div className={styles.containerTitleFull}>
                    <p>Información del dispositivo</p>
                  </div>
                  <div className={styles.containerInfo}>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Marca del dispositivo</p>
                      <p className={`${styles.text}`}>Motorola</p>
                    </div>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Modelo</p>
                      <p className={`${styles.text}`}>Moto G52 - 63</p>
                    </div>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Sistema operativo</p>
                      <p className={`${styles.text}`}>Android 12.5.2</p>
                    </div>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Versión del sistema</p>
                      <p className={`${styles.text}`}>1625363HDGD</p>
                    </div>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>App</p>
                      <p className={`${styles.text}`}>MIAid</p>
                    </div>
                    <div className={styles.containerDataHorizontal}>
                      <p className={`${styles.text} ${styles.bold}`}>Versión de la App</p>
                      <p className={`${styles.text}`}>1.4.8</p>
                    </div>
                  </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </> : <div className={styles.containerLoad}><CircularProgress /></div>}
    </div>
    </>
  );
}