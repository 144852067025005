import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styles from "./Modal.module.scss"
import fromExponential from 'from-exponential';

export default function Modal({data, close}) {
  console.log(data)
    
return (
  <div className={styles.containerModal}>
    <div className={styles.modal}>
      <div className={styles.containerData}>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Calidad</p>
          <p className={styles.text}>
            {data.livenessQuality === "Y" ? <p className={styles.pill}>
            <div className={styles.circle}></div>
            <p>{data.livenessQualityValue.toFixed(3)}</p>
          </p> :
          <p className={`${styles.pill} ${styles.pillFalse}`}>
            <div className={styles.circle}></div>
            <p>{data.livenessQualityValue.toFixed(3)}</p>
          </p>}
          </p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Luminancia</p>
          <p className={styles.text}>
          {data.luminance === "Y" ? <p className={styles.pill}>
            <div className={styles.circle}></div>
            <p>{data.luminanceValue.toFixed(3)}</p>
          </p> :
          <p className={`${styles.pill} ${styles.pillFalse}`}>
            <div className={styles.circle}></div>
            <p>{data.luminanceValue.toFixed(3)}</p>
          </p>}
          </p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Oclusión</p>
          <p className={styles.text}>
          {data.occluded === "Y" ? <p className={styles.pill}>
            <div className={styles.circle}></div>
            <p>{data.occlusionValue.toFixed(3)}</p>
          </p> :
          <p className={`${styles.pill} ${styles.pillFalse}`}>
            <div className={styles.circle}></div>
            <p>{data.occlusionValue.toFixed(3)}</p>
          </p>}
          </p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Ojos cerrados</p>
          {data.eyesClosed === "Y" ? <p className={styles.pill}>
            <div className={styles.circle}></div>
            <p>TRUE</p>
          </p> :
          <p className={`${styles.pill} ${styles.pillFalse}`}>
            <div className={styles.circle}></div>
            <p>FALSE</p>
          </p>}
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Cierre ojo izquierdo</p>
          <p className={styles.text}>{fromExponential(data.eyeLeftClosed) ?? 88.2}</p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Cierre ojo derecho</p>
          <p className={styles.text}>{fromExponential(data.eyeRightClosed) ?? 88.2}</p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Boca abierta</p>
          {data.mouthOpen === "Y" ? <p className={styles.pill}>
            <div className={styles.circle}></div>
            <p>{data?.mouthOpenValue?.toFixed(3) ?? "0.000"}</p>
          </p> :
          <p className={`${styles.pill} ${styles.pillFalse}`}>
            <div className={styles.circle}></div>
            <p>{data?.mouthOpenValue?.toFixed(3) ?? "0.000"}</p>
          </p>}
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Posición</p>
          {data.notFront === "N" ? <p className={styles.pill}>
            <div className={styles.circle}></div>
            <p>{"De frente"}</p>
          </p> :
          <p className={`${styles.pill} ${styles.pillFalse}`}>
            <div className={styles.circle}></div>
            <p>{"De costado"}</p>
          </p>}
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Distancia entre ojos</p>
          <p className={styles.text}>{`${data.eyesDistance.toFixed(3)} mm.` ?? "N/A"}</p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Yaw</p>
          <p className={styles.text}>{data.yaw.toFixed(3) ?? 24.5}</p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Pitch</p>
          <p className={styles.text}>{data.pitch.toFixed(3) ?? 99.1}</p>
        </div>
        <div className={styles.containerInfo}>
          <p className={`${styles.text} ${styles.bold}`}>Roll</p>
          <p className={styles.text}>{data?.roll.toFixed(3) ?? 0.85}</p>
        </div>
      </div>
    </div>
  </div>
  );
}