import HeaderButton from "../UI/HeaderButton/HeaderButton";
import Buttons from "../UI/ButtonsContainer/Buttons";
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import { ACTIONS } from "../../const/Actions";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import styles from "./Table.module.scss"
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import { getUser } from "../../Services/getUser";
import { columns } from "../../const/Columns";
import XGridServer from "../UI/Grid/GridServer/GridServer";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { BackBtn } from "../UI/BackBtn/BackBtn";

export default function Table() {
    const {selectRow, setSelectRow, setDetailRow} = useContext(Context);
    const [userList, setUserList] = useState([])
    const [input, setInput] = useState("")
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [rowsCount, setRowsCount] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const navigate = useNavigate()
    const urlParam = sessionStorage.getItem("tenantId")

    const deleteFilter = () => {
        setSearch("")
        setInput("")
    }    
    useEffect(() => {
        (async () => {
            const user = search ? await getUser(Number(urlParam) ,search, null, pageSize) : await getUser(Number(urlParam) ,null, page, pageSize)
            setRowsCount(user.status.id)
            if (user) {
                let formatList = user.result.map((data, i) => ({
                    id: data.usuarioId,
                    usuarioId: data.usuarioId,
                    usuarioNombre: data.usuarioNombre,
                    tenantId: data.tenantId,
                    tenantNombre: data.tenantNombre,
                    usuarioEVANId: data.usuarioEVANId,
                    registracionPrimera: data.registracionPrimera !== "" ? `${new Date(data?.registracionPrimera).toLocaleDateString("es-ES")} ${new Date(data.registracionPrimera).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
                    registracionUltima: data.registracionUltima !== "" ? `${new Date(data?.registracionUltima).toLocaleDateString("es-ES")} ${new Date(data.registracionUltima).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
                    registracionCantidad: data.registracionCantidad,
                    autenticacionUltima: data.autenticacionUltima !== "" ? `${new Date(data?.autenticacionUltima).toLocaleDateString("es-ES")} ${new Date(data.autenticacionUltima).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
                    autenticacionCantidad: data.autenticacionCantidad
                }))
                setUserList(formatList)
            }
        })();
    }, [search, page, pageSize]);
    useEffect(() => {
        // const permiso = sessionStorage.getItem("user")
        // if(!permiso){
        //     navigate("/")
        // }
        setDetailRow({ status: false, row: {} })
        return () => {
            // setSelectRow({ status: false, row: {} })
        }
    }, []);

    return (
    <>
        <Header/>
        <div className={styles.container}>
            <div className={styles.containerFilters}>
                <div className={styles.containerSearch}>
                    <div className={styles.containerInput}>
                        <label>Evan ID</label>
                        <input className={styles.input} value={input} onChange={(e) => setInput(e.target.value)}/>
                    </div>
                    <div className={styles.containerBtnFilter}>
                        <HeaderButton
                            text={"Filtrar"}
                            data={{
                                title: "Filtrar",
                                button: "Filtrar",
                            }}
                            click={() => setSearch(input)}
                        >
                            <FilterAltRoundedIcon />
                        </HeaderButton>
                        <HeaderButton
                            text={"Borrar filtros"}
                            data={{
                                title: "Borrar filtros",
                                button: "Borrar filtros",
                            }}
                            click={() => deleteFilter()}
                        >
                            <FilterAltOffRoundedIcon />
                        </HeaderButton>
                    </div>
                </div>
                <Buttons filtersApplied={true}>
                    <HeaderButton
                        disabled={selectRow.status ? false : true}
                        text={"Autenticación"}
                        link={`autenticaciones`}
                        data={{
                            action: ACTIONS.AUTH,
                            title: "Autenticación",
                            button: "Autenticación",
                        }}
                    >
                        <HowToRegOutlinedIcon />
                    </HeaderButton>
                    <HeaderButton
                        disabled={selectRow.status ? false : true}
                        text={"Registración"}
                        link={`registraciones`}
                        data={{
                            action: ACTIONS.REG,
                            title: "Registración",
                            button: "Registración",
                        }}
                    >
                        <AppRegistrationRoundedIcon />
                    </HeaderButton>
                </Buttons>
            </div>
            <XGridServer columns={columns} setNumberPage={setPage} rowsCount={rowsCount} list={userList} rowSelect={selectRow} setRow={setSelectRow} pageSize={pageSize} onPageSizeChange={setPageSize}/>
        </div>
    </>
  );
}