import React, { useEffect, useState } from 'react'
import styles from "./Dashboard.module.scss"
import Header from '../Header/Header'
import { Banner } from '../UI/Banner/Banner'
import { CardStats } from '../UI/CardStats/CardStats'
import { TableDashboard } from '../UI/TableDashboard/TableDashboard'
import { CardGraph } from '../UI/CardGraph/CardGraph'
import { getDashboardData } from '../../Services/getDashboardData'

export const Dashboard = () => {
  const [data, setData] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [totalData, setTotalData] = useState()

  function convertirFecha (fechaString) {
    var fechaSp = fechaString.split("-");
    var anio = new Date().getFullYear();
    anio = fechaSp[0];
    var mes = fechaSp[1];
  
    return new Date(anio, mes);
  }

  const getData = async () => {
    let tenant = sessionStorage.getItem("tenantNombre")
    const response = await getDashboardData(tenant)
    console.log(response)
    let order = response.result[0].datosMensuales.sort((a, b) => convertirFecha(a.mes) - convertirFecha(b.mes))
    setDataTable(response.result[0].ultimasAutenticaciones)
    setTotalData(response.result[0].datosGenerales)
    setData(order)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <Header/>
      <div className={styles.container}>
        <h1 className={styles.title}>Bienvenido a Evan, Juan</h1>
        <div className={styles.containerGrid}>
          <Banner/>
          <div className={styles.containerDiv}>
            {data.length > 0 &&<CardStats title="Total Usuarios" value={totalData?.totalUsuarios} stonk={true}/>}
            {data.length > 0 &&<CardStats title="Usuarios Nuevos" value={data?.at(-1).totalUsuarios} stonk={false}/>}
          </div>
          <div className={styles.containerDiv}>
            <TableDashboard title="Autenticaciones recientes" data={dataTable}/>
            <CardGraph title="Métrica de Usuarios" type="line" data={data}/>
          </div>
          <CardGraph title="Tasa de Match" type="pie" data={data}/>
        </div>
      </div>
    </>
  )
}
