import axios from "axios";

export const getImg = async(url) => {
    let img
    try {
        img = await axios.get(
            url,
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return img
}