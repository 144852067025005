import React from 'react'
import styles from "./TableDashboard.module.scss"

export const TableDashboard = ({title, value, data}) => {
    console.log(data)
  return (
    <div className={styles.containerCard}>
        <div className={styles.containerTop}>
            <h2 className={styles.title}>{title || "Total"}</h2>
        </div>
        <div className={styles.containerTable}>
            <div className={styles.header}>
                <p>EVAN ID</p>
                <p>FECHA</p>
                <p>FACTOR</p>
                <p>RESULTADO</p>
            </div>
            {data.map((item, index) => {
                if(index < 5){
                    return(
                    <div className={styles.row} key={index}>
                        <p>{item.usuarioEVANId}</p>
                        <p>{`${new Date(item.fecha).toLocaleDateString("es-ES")} ${new Date(item.fecha).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}`}</p>
                        <p>{item.factorNombre}</p>
                        <div className={styles.containerPill}>
                            <div className={`${styles.pill} ${item.resultadoOk === true ? styles.green : styles.red}`}>
                                <div className={styles.point}/><p>{item.resultadoOk === true ? "Match" : "Error"}</p>
                            </div>
                        </div>
                    </div>)
                }}
            )}
        </div>
    </div>
  )
}
