// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListDrawer_container__yr5Cg {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.ListDrawer_containerItem__9g9vE {
  display: flex;
  color: #f5f5f5;
  border-radius: 8px;
  background-color: #06132F;
  width: 100%;
  height: 56px;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  gap: 10px;
  padding-left: 14px;
  cursor: pointer;
}

.ListDrawer_containerItem__9g9vE:hover {
  background-color: #0A2052;
}

.ListDrawer_select__bvopG {
  background-color: #FF6D00;
  font-weight: 700;
}

.ListDrawer_select__bvopG:hover {
  background-color: #FF6D00;
}

.ListDrawer_itemListIcon__nvsIq {
  height: 24px;
}

@media only screen and (max-width: 1560px) {
  .ListDrawer_container__yr5Cg {
    gap: 4px;
  }
  .ListDrawer_containerItem__9g9vE {
    width: 100%;
    height: 48px !important;
    font-size: 16px;
  }
  .ListDrawer_itemListIcon__nvsIq {
    height: 21px;
    width: 21px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/list/ListDrawer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;AACJ;;AACA;EACI,aAAA;EACA,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,yBAAA;AAGJ;;AADA;EACI,yBAAA;EACA,gBAAA;AAIJ;;AAFA;EACI,yBAAA;AAKJ;;AAHA;EACI,YAAA;AAMJ;;AAJA;EACI;IACI,QAAA;EAON;EALE;IACI,WAAA;IACA,uBAAA;IACA,eAAA;EAON;EALE;IACI,YAAA;IACA,WAAA;EAON;AACF","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    padding: 20px;\n}\n.containerItem {\n    display: flex;\n    color: #f5f5f5;\n    border-radius: 8px;\n    background-color: #06132F;\n    width: 100%;\n    height: 56px;\n    font-size: 18px;\n    font-weight: 400;\n    align-items: center;\n    gap: 10px;\n    padding-left: 14px;\n    cursor: pointer;\n}\n.containerItem:hover {\n    background-color: #0A2052;\n}  \n.select {\n    background-color: #FF6D00;\n    font-weight: 700;\n}\n.select:hover {\n    background-color: #FF6D00;\n}\n.itemListIcon{\n    height: 24px;\n}\n@media only screen and (max-width: 1560px){\n    .container {\n        gap: 4px;\n    }\n    .containerItem {\n        width: 100%;\n        height: 48px !important;\n        font-size: 16px;\n    }\n    .itemListIcon{\n        height: 21px;\n        width: 21px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ListDrawer_container__yr5Cg`,
	"containerItem": `ListDrawer_containerItem__9g9vE`,
	"select": `ListDrawer_select__bvopG`,
	"itemListIcon": `ListDrawer_itemListIcon__nvsIq`
};
export default ___CSS_LOADER_EXPORT___;
