import React from 'react'

import styles from "./Card.module.scss"
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const errorValues = ["Error", "Fail", "No detectado"]

export const Card = ({livenness, openModal, principal, second, third}) => {
  return (
    <div className={styles.container}>
        <div className={`${styles.containerTop} ${principal?.result === "N/A" ? styles.not : errorValues.includes(principal?.result) ? styles.error :styles.success}`}>
            <div className={styles.containerResult}>
                <p className={styles.title}>{principal?.title || "Resultado"}</p>
                <p className={styles.result}>{principal?.result || "Match"}</p>
            </div>
            <div className={styles.containerIcon}>
                {principal?.result === "N/A" ? <RemoveRoundedIcon/> : errorValues.includes(principal?.result) ? <CloseRoundedIcon/> : <CheckRoundedIcon/>}
            </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.containerDown}>
            <div className={styles.data}>
                <p className={`${styles.title} ${styles.secondary}`}>{second?.title || ""}</p>
                <p className={styles.point}>{second?.result || ""}</p>
            </div>
            {third && <div className={styles.data}>
                <p className={`${styles.title} ${styles.secondary}`}>{third?.title || ""}</p>
                <p className={styles.point}>{third?.result || ""}</p>
            </div>}
        </div>
    </div>
  )
}
